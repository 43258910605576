<template>
  <div>
    <ek-table
      :items="guideList"
      :columns="guidesCols"
      @details="goToDetails"
      no_delete
    >
      <template slot="items.lastAccordanceDate" slot-scope="{ value }">
        {{ value ? new Date(value).toLocaleDateString() : "_" }}
      </template>
    </ek-table>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapState({
      guides: ({ invoiceGuide }) => invoiceGuide.guides,
      guidesCols: ({ invoiceGuide }) => invoiceGuide.guidesCols,
    }),
    ...mapGetters(["guideList"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getGuidesList", "deleteGuides"]),
    goToDetails({ row }) {
      let rowDotName = row.name;
      this.$router.push({
        path: `/admin/invoice/invoiceGuide/${row.id}`,
        query: { name: rowDotName },
      });
    },
  },
  created() {
    this.getGuidesList();
  },
};
</script>